import { baseUrl } from "./http";

const base_url = `${baseUrl()}/v1/units`;

const apiParkingSpaces = {
  findAll: async (unit_id) => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/${unit_id}/parking-spaces`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createOne: async (body) => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/${body.unitId}/parking-spaces`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          spaceCode: body.spaceCode,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            return res.json();
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteOne: async (unitId, id) => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/${unitId}/parking-spaces/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default apiParkingSpaces;
