import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import usersApi from "../../api/users";

export const findAllUsers = createAsyncThunk(
  "users/findAll",
  async ({ query, page, size }, thunkAPI) => {
    const users = await usersApi.findAll({
      page,
      size,
      query,
    });
    return users;
  }
);

export const findOneUser = createAsyncThunk(
  "users/findOne",
  async (id, thunkAPI) => {
    const users = await usersApi.findOne(id);
    return users;
  }
);

export const updateOneUser = createAsyncThunk(
  "users/updateOne",
  async ({ id, body }, thunkAPI) => {
    const users = await usersApi.updateOne(id, body);
    return users;
  }
);

const initialState = {
  user: {},
  users: [],
  paginate: {
    page: 0,
    size: 5,
    count: 0,
  },
  loading: false,
};

export const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllUsers.fulfilled, (state, { payload }) => {
        state.users = payload.rows;
        state.paginate = {
          page: parseInt(payload.page),
          size: parseInt(payload.size),
          count: parseInt(payload.count),
        };
        state.loading = false;
      })
      .addCase(findAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllUsers.rejected, (state) => {
        state.loading = false;
        state.users = [];
      })
      .addCase(findOneUser.fulfilled, (state, { payload }) => {
        state.user = {
          name: payload.name,
          email: payload.email,
          role: payload.permissions[0].role,
          tenants: payload.permissions[0].tenants,
          units: payload.permissions[0].units,
          vehicles: payload.permissions[0].vehicles,
          visitors: payload.permissions[0].visitors,
          mails: payload.permissions[0].mails,
          guests: payload.permissions[0].guests,
          entrances: payload.permissions[0].entrances,
          users: payload.permissions[0].users,
          reports: payload.permissions[0].reports,
        };
        state.loading = false;
      })
      .addCase(findOneUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(findOneUser.rejected, (state) => {
        state.loading = false;
        state.user = {};
      })
      .addCase(updateOneUser.fulfilled, (state) => {
        state.user = {};
        state.loading = false;
      })
      .addCase(updateOneUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOneUser.rejected, (state) => {
        state.loading = false;
      })
  },
});

export const {
  reducer,
  actions: { setUser, setPaginate, setLoading },
} = slice;
