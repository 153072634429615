import { Box } from "@mui/material";

export const SplashScreen = () => (
  <Box
    sx={{
      alignItems: "center",
      backgroundColor: "background.paper",
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      justifyContent: "center",
      left: 0,
      p: 3,
      position: "fixed",
      top: 0,
      width: "100vw",
      zIndex: 1400,
    }}
  >
    <Box
      sx={{
        display: "inline-flex",
        width: 304.8,
        height: 137.4,
      }}
    >
      <img
        src={require("../../assets/img/logo.png")}
        alt="logo"
        style={{
          width: 304.8,
          height: 137.4,
        }}
      />
    </Box>
  </Box>
);
