import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import parkingSpacesApi from "../../api/parkingSpaces";

export const findAllParkingSpaces = createAsyncThunk(
  "parkingSpaces/findAll",
  async (unitId, _) => {
    const parkingSpaces = await parkingSpacesApi.findAll(unitId);
    return parkingSpaces;
  }
);

const initialState = {
  parkingSpace: {},
  parkingSpaces: [],
  loading: false,
};

export const slice = createSlice({
  name: "parkingSpaces",
  initialState,
  reducers: {
    setParkingSpace: (state, action) => {
      state.unit = action.payload;
    },
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
    setLoading: (state, action) => { 
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllParkingSpaces.fulfilled, (state, { payload }) => {
        state.parkingSpaces = payload;
        state.loading = false;
      })
      .addCase(findAllParkingSpaces.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllParkingSpaces.rejected, (state) => {
        state.loading = false;
        state.parkingSpaces = [];
      });
      
  },
});

export const {
  reducer,
  actions: { setParkingSpace, setPaginate, setLoading },
} = slice;
