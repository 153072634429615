import { Image, WhatsApp } from "@mui/icons-material";
import { Avatar, Box, IconButton, Stack, SvgIcon, Tooltip, Typography, useTheme } from "@mui/material";
import { useMemo } from "react";

import { useAuth } from "../../hooks/use-auth";
import { usePathname } from "../../hooks/use-pathname";
import { AccountButton } from "./account-button";
import { NotificationsButton } from "./notifications-button";
import { ThemeButton } from "./theme-button";
import { TopNavSection } from "./top-nav-section";

const useCssVars = () => {
  const theme = useTheme();

  return useMemo(() => {
    return {
      "--nav-bg": theme.palette.neutral[200],
      "--nav-color": theme.palette.common.white,
      "--nav-divider-color": theme.palette.neutral[700],
      "--nav-border-color": "transparent",
      "--nav-logo-border": theme.palette.neutral[700],
      "--nav-item-color": theme.palette.neutral[400],
      "--nav-item-hover-bg": "rgba(255, 255, 255, 0.04)",
      "--nav-item-active-bg": "rgba(255, 255, 255, 0.04)",
      "--nav-item-active-color": theme.palette.common.white,
      "--nav-item-disabled-color": theme.palette.neutral[500],
      "--nav-item-icon-color": theme.palette.neutral[400],
      "--nav-item-icon-active-color": theme.palette.primary.main,
      "--nav-item-icon-disabled-color": theme.palette.neutral[500],
      "--nav-item-chevron-color": theme.palette.neutral[600],
      "--nav-scrollbar-color": theme.palette.neutral[400],
    };
  }, [theme]);
};

export const TopNav = ({ sections = [], handleDrawerOpen, settings }) => {
  const cssVars = useCssVars();
  const pathname = usePathname();
  const theme = useTheme();
  const { condo, user,  } = useAuth();
  const { handleUpdate } = settings;

  return (
    <Box
      component="header"
      sx={{
        ...cssVars,
        borderBottomColor: "var(--nav-border-color)",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        color: "var(--nav-color)",
        left: 0,
        position: "sticky",
        top: 0,
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          py: 1,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          <Box
            sx={{
              display: "inline-flex",
              height: 65,
              width: 150,
            }}
          >
            <img
              src={require("../../assets/img/logo.png")}
              alt="logo"
              style={{
                width: 150,
                height: 65,
              }}
            />
          </Box>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Stack direction="column" spacing={0}>
            {condo.typeNotification === "WHATSAPP" && (
              <Tooltip
                title={`WhatsApp está ${
                  condo.statusNotification?.instance_data?.phone_connected
                    ? "online"
                    : "offline"
                }`}
              >
                <WhatsApp
                  sx={{
                    fontSize: 40,
                    color:
                      condo.statusNotification?.instance_data
                        ?.phone_connected === true
                        ? "#10B981"
                        : "#f04439",
                  }}
                />
              </Tooltip>
            )}
          </Stack>
          <Stack direction="column" spacing={0}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: 10, margin: 0, padding: 0 }}
            >
              Condomínio
            </Typography>
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ fontSize: 10, margin: 0, padding: 0, lineHeight: 0.7 }}
            >
              {condo?.name}
            </Typography>
            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: 10, width: "100%" }}
              >
                Usuário
              </Typography>
              <Typography
                variant="h6"
                color="text.secondary"
                sx={{ fontSize: 10, ml: "auto", padding: 0 }}
              >
                {condo?.role}
              </Typography>
            </Stack>
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ fontSize: 10, margin: 0, padding: 0, lineHeight: 0.7 }}
            >
              {user?.name}
            </Typography>
          </Stack>
          {/* {condo?.integration?.devices.length > 0 && (
            <NotificationsButton handleDrawerOpen={handleDrawerOpen} />
          )} */}
          <ThemeButton
            handleThemeUpdate={handleUpdate}
            paletteMode={settings.paletteMode}
          />
          <AccountButton />
        </Stack>
      </Stack>
      <Box
        sx={{
          borderTopWidth: 1,
          borderTopStyle: "solid",
          borderTopColor: "var(--nav-divider-color)",
        }}
      >
        <Stack
          alignItems="center"
          component="nav"
          direction="row"
          spacing={1}
          sx={{
            px: 2,
            py: 0.5,
            backgroundColor: "primary.main",
          }}
        >
          {sections?.map((section, index) => (
            <TopNavSection
              items={section.items}
              key={index}
              pathname={pathname}
              subheader={section.subheader}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};
