import { ImportExport } from "@mui/icons-material";
import { Box, Button, Divider, ListItemButton, ListItemIcon, ListItemText, Popover, SvgIcon, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { User } from "react-feather";

import { RouterLink } from "../../../components/utils/router-link";
import { useAuth } from "../../../hooks/use-auth";
import { useRouter } from "../../../hooks/use-router";
import paths from "../../../pages/paths";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const router = useRouter();
  const { signOut, user, condos } = useAuth();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: 12,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {user?.name}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
          sx={{
            fontSize: 12,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {user?.email}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        <ListItemButton
          component={RouterLink}
          href={paths.system.account}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <User />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="body1"
                sx={{ fontSize: 12, textAlign: "left" }}
              >
                Perfil de Usuário
              </Typography>
            }
          />
        </ListItemButton>
        {/* {condos?.length > 1 && (
          <ListItemButton
            component={RouterLink}
            href={paths.system.change}
            onClick={onClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <ImportExport />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  sx={{ fontSize: 12, textAlign: "left" }}
                >
                  Trocar Condomínio
                </Typography>
              }
            />
          </ListItemButton>
        )} */}
      </Box>
      <Divider sx={{ my: "0 !important" }} />
      <Box
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "center",
        }}
      >
        <Button color="inherit" onClick={() => signOut()} size="small">
          Sair
        </Button>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
