import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import reportApi from "../../api/report";

export const findAllReportMail = createAsyncThunk(
  "report/findAllReportMail",
  async ({ page, size, query, startDate, endDate, type }, thunkAPI) => {
    const mails = await reportApi.findAllMail({
      page,
      size,
      query,
      startDate,
      endDate,
      type,
    });
    return mails;
  }
);

const initialState = {
  mail: {
    mails: [],
    filters: {
      query: "",
      startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      type: "CREATED",
    },
    loading: false,
    paginate: {
      page: 0,
      size: 10,
      count: 0,
    },
  },
};

export const slice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setReportMails: (state, action) => {
      state.mail.mails = action.payload;
    },
    setPaginateMails: (state, action) => {
      state.mail.paginate = action.payload;
    },
    setLoadingMails: (state, action) => {
      state.mail.loading = action.payload;
    },
    setFilterMails: (state, action) => {
      state.mail.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllReportMail.fulfilled, (state, { payload }) => {
        state.mail.mails = payload.rows;
        state.mail.paginate = {
          page: parseInt(payload.page),
          size: parseInt(payload.size),
          count: parseInt(payload.count),
        };
        state.mail.loading = false;
      })
      .addCase(findAllReportMail.pending, (state) => {
        state.mail.loading = true;
      })
      .addCase(findAllReportMail.rejected, (state) => {
        state.mail.loading = false;
        state.mail.mails = [];
      });
  },
});

export const {
  reducer,
  actions: {
    setReportMails,
    setPaginateMails,
    setLoadingMails,
    setFilterMails,
  },
} = slice;
