import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import notificationsApi from "../../api/notifications";

export const findAllNotifications = createAsyncThunk(
  "notifications/findAll",
  async ({ query, page, size }, thunkAPI) => {
    const notifications = await notificationsApi.findAll({
      page,
      size,
      query,
    });
    return notifications;
  }
);

export const findOneNotification = createAsyncThunk(
  "notifications/findOne",
  async (id, thunkAPI) => {
    const notifications = await notificationsApi.findOne(id);
    return notifications;
  }
);

export const updateOneNotification = createAsyncThunk(
  "notifications/updateOne",
  async ({ id, body }, thunkAPI) => {
    const notifications = await notificationsApi.updateOne(id, body);
    return notifications;
  }
);

export const sendAllNotification = createAsyncThunk(
  "notifications/updateOne",
  async ({ id }, thunkAPI) => {
    const notifications = await notificationsApi.sendAll(id);
    return notifications;
  }
);

const initialState = {
  notification: {},
  notifications: [],
  paginate: {
    page: 0,
    size: 5,
    count: 0,
  },
  loading: false,
};

export const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllNotifications.fulfilled, (state, { payload }) => {
        state.notifications = payload.rows;
        state.paginate = {
          page: parseInt(payload.page),
          size: parseInt(payload.size),
          count: parseInt(payload.count),
        };
        state.loading = false;
      })
      .addCase(findAllNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllNotifications.rejected, (state) => {
        state.loading = false;
        state.notifications = [];
      })
      .addCase(findOneNotification.fulfilled, (state, { payload }) => {
        state.notification = payload;
        state.loading = false;
      })
      .addCase(findOneNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(findOneNotification.rejected, (state) => {
        state.loading = false;
        state.notification = {};
      })
      .addCase(updateOneNotification.fulfilled, (state,) => {
        state.notification = {};
        state.loading = false;
      })
      .addCase(updateOneNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOneNotification.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  reducer,
  actions: { setNotification, setPaginate, setLoading },
} = slice;
