import { Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: { background: "rgba(0,0,0,0.1)" },
}));

const Loading = ({ loading, style = {} }) => {
  const classes = useStyles();

  return (
    <Backdrop sx={{ ...style }} className={classes.backdrop} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
