import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import otherApi from "../../api/others";
import vehiclesApi from "../../api/vehicles";

export const findVehiclePlate = createAsyncThunk(
  "vehicles/findPlateVehicle",
  async (plate, _) => {
    const vehicle = await otherApi.consultPlateVehicle(plate);
    return { ...vehicle, plate };
  }
);

export const findAllVehicles = createAsyncThunk(
  "vehicles/findAll",
  async ({ query, page, size }, _) => {
    const vehicles = await vehiclesApi.findAll({
      page,
      size,
      query,
    });
    return vehicles;
  }
);

export const findOneVehicle = createAsyncThunk(
  "vehicles/findOne",
  async (id, _) => {
    const vehicles = await vehiclesApi.findOne(id);
    return vehicles;
  }
);

const initialState = {
  vehicle: {},
  vehicles: [],
  paginate: {
    page: 0,
    size: 5,
    count: 0,
  },
  loading: false,
  filters: {
    query: "",
  },
};

export const slice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    setVehicle: (state, action) => {
      state.vehicle = action.payload;
    },
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllVehicles.fulfilled, (state, { payload }) => {
        state.vehicles = payload.rows;
        state.paginate = {
          page: parseInt(payload.page),
          size: parseInt(payload.size),
          count: parseInt(payload.count),
        };
        state.loading = false;
      })
      .addCase(findAllVehicles.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllVehicles.rejected, (state, { payload }) => {
        state.loading = !payload ? true : false;
        state.vehicles = [];
      })
      .addCase(findOneVehicle.fulfilled, (state, { payload }) => {
        state.vehicle = {
          ...payload,
          tenantId: payload.tenant?.id,
        };
        state.loading = false;
      })
      .addCase(findOneVehicle.pending, (state) => {
        state.loading = true;
      })
      .addCase(findOneVehicle.rejected, (state) => {
        state.loading = false;
        state.vehicle = {};
      })
      .addCase(findVehiclePlate.fulfilled, (state, { payload }) => {
        state.vehicle = {
          ...state.vehicle,
          plate: payload?.placa,
          brand: payload?.marca,
          model: payload?.modelo,
          year: payload?.ano,
          color: payload?.cor,
        };
        state.vehicle.plate = payload.plate;
        state.loading = false;
      })
      .addCase(findVehiclePlate.pending, (state) => {
        state.loading = true;
      })
      .addCase(findVehiclePlate.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  reducer,
  actions: { setVehicle, setPaginate, setFilters, setLoading },
} = slice;
