import { combineReducers } from "@reduxjs/toolkit";

import { reducer as credentialReducer } from './slices/credentials';
import { reducer as dashboardReducer } from './slices/dashboard';
import { reducer as entranceReducer } from "./slices/entrance";
import { reducer as guestsReducer } from "./slices/guests";
import { reducer as mailsReducer } from "./slices/mails";
import { reducer as notificationReducer } from './slices/notifications';
import { reducer as parkingSpacesReducer } from './slices/parkingSpaces';
import { reducer as reportReducer } from "./slices/report";
import { reducer as settingsReducer } from "./slices/settings";
import { reducer as tenantsReducer } from "./slices/tenants";
import { reducer as unitsReducer } from "./slices/units";
import { reducer as usersReducer } from './slices/users';
import { reducer as vehiclesReducer } from "./slices/vehicles";
import { reducer as vehiclesTagUHFReducer } from './slices/vehiclesTagUHF';
import { reducer as visitorsReducer } from "./slices/visitors";

export const rootReducer = combineReducers({
  visitors: visitorsReducer,
  units: unitsReducer,
  tenants: tenantsReducer,
  vehicles: vehiclesReducer,
  entrances: entranceReducer,
  settings: settingsReducer,
  mails: mailsReducer,
  dashboard: dashboardReducer,
  vehicle_tag: vehiclesTagUHFReducer,
  parkingSpaces: parkingSpacesReducer,
  users: usersReducer,
  notifications: notificationReducer,
  credentials: credentialReducer,
  guests: guestsReducer,
  report: reportReducer,
});
