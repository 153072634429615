import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import settingsApi from "../../api/settings";

export const findOneSettings = createAsyncThunk(
  "settings/findOne",
  async () => {
    const settings = await settingsApi.findOne();
    return settings;
  }
);

export const findAllIntegrations = createAsyncThunk(
  "settings/findAllIntegrations",
  async () => {
    const integrations = await settingsApi.findAllIntegrations();
    return integrations;
  }
);

const initialState = {
  setting: {},
  loading: false,
  integrations: [],
};

export const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSetting: (state, action) => {
      state.setting = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findOneSettings.fulfilled, (state, action) => {
        state.setting = action.payload;
        state.loading = false;
      })
      .addCase(findOneSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(findOneSettings.rejected, (state) => {
        state.loading = false;
      })
      .addCase(findAllIntegrations.fulfilled, (state, action) => {
        state.integrations = action.payload;
        state.loading = false;
      })
      .addCase(findAllIntegrations.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllIntegrations.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  reducer,
  actions: { setSetting, setLoading },
} = slice;
