import { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon,
} from "@mui/material";
import { useUpdateEffect } from "../../../hooks/use-update-effect";
import { useRouter } from "../../../hooks/use-router";

const UniqListSearch = (props) => {
  const { onFiltersChange, path, eventProps } = props;
  const queryRef = useRef(null);
  const [filters, setFilters] = useState({});
  const router = useRouter();

  const handleFiltersUpdate = useCallback(() => {
    onFiltersChange?.(filters);
  }, [filters, onFiltersChange]);

  useUpdateEffect(() => {
    handleFiltersUpdate();
  }, [filters, handleFiltersUpdate]);

  const handleQueryChange = useCallback((event) => {
    event.preventDefault();
    setFilters((prevState) => ({
      ...prevState,
      query: queryRef.current?.value,
    }));
  }, []);

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        spacing={3}
        sx={{ p: 2 }}
      >
        <Box component="form" onSubmit={handleQueryChange} sx={{ flexGrow: 1 }}>
          <OutlinedInput
            defaultValue=""
            fullWidth
            inputProps={{
              ref: queryRef,
            }}
            placeholder="Digite aqui sua pesquisa"
            startAdornment={
              <InputAdornment position="start">
                <SvgIcon>
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            }
          />
        </Box>
        {(eventProps || path) && (
          <Button
            variant="contained"
            sx={{ width: "100px", ml: 0.5 }}
            onClick={() => {
              if (eventProps) {
                eventProps();
              } else {
                router.push(`${path}/0/new`);
              }
            }}
          >
            <AddCircleIcon sx={{ mr: 0.5 }} /> Novo
          </Button>
        )}
      </Stack>
    </>
  );
};

UniqListSearch.propTypes = {
  onFiltersChange: PropTypes.func,
  path: PropTypes.string,
};

export default UniqListSearch;
