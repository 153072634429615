import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import vehiclesTagUHFApi from "../../api/vehiclesTagUHF";

export const findOneVehicleTag = createAsyncThunk(
  "vehicles/tag/uhf/findOne",
  async (id, thunkAPI) => {
    const tag = await vehiclesTagUHFApi.findOne(id);
    return tag;
  }
);

export const updateOneVehicleTag = createAsyncThunk(
  "vehicles/updateOne",
  async ({ id, body }, thunkAPI) => {
    const tag = await vehiclesTagUHFApi.updateOne(id, body);
    return tag;
  }
);

const initialState = {
  vehicle_tag: {},
  loading: false,
};

export const slice = createSlice({
  name: "vehicle_tag",
  initialState,
  reducers: {
    setVehicleTag: (state, action) => {
      state.vehicle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findOneVehicleTag.fulfilled, (state, { payload }) => {
        if (payload) {
          state.vehicle_tag = payload;
        } else {
          state.vehicle_tag = {
            id: "",
            integrationId: "",
            hexa: "",
            decimal: "",
            size: 34,
            vehicleId: "",
          };
        }
        state.loading = false;
      })
      .addCase(findOneVehicleTag.pending, (state) => {
        state.loading = true;
      })
      .addCase(findOneVehicleTag.rejected, (state) => {
        state.loading = false;
        state.vehicle = {};
      })
      .addCase(updateOneVehicleTag.fulfilled, (state) => {
        state.vehicle_tag = {};
        state.loading = false;
      })
      .addCase(updateOneVehicleTag.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOneVehicleTag.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  reducer,
  actions: { setVehicleTag },
} = slice;
