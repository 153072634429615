import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { Fragment, useCallback, useEffect, useState } from "react";

function AutoCompleteInput(props) {
  const {
    id,
    options = [],
    label,
    load,
    value,
    onBlur,
    onChange,
    onInputChange,
    sx = {},
  } = props;
  const [open, setOpen] = useState(false);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    (() => load(value ? value : ""))();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    open && load(value ? value : "");
  }, [value]);
    
  const loadOptions = useCallback(
    (inputValue) => {
      onInputChange(inputValue ? inputValue : "");
    },
    [open, value]
  );

  return (
    <Autocomplete
      id={id}
      open={open}
      sx={sx}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={loading}
      onBlur={onBlur}
      onChange={(e, value) => onChange(value)}
      onInputChange={(e, value) => loadOptions(value)}
      noOptionsText="Não existe opções."
      inputValue={value}
      filterOptions={(options, params) => {
        return options.filter((option) => {
          const { inputValue } = params;
          return option.title?.toLowerCase().includes(String(inputValue)?.toLowerCase());
        })
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default AutoCompleteInput;
