import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { Badge, IconButton, SvgIcon, Tooltip } from "@mui/material";

export const ThemeButton = ({ handleThemeUpdate, paletteMode }) => {
  return (
    <>
      <Tooltip title={paletteMode === "light" ? "Modo Escuro" : "Modo Claro"}>
        <IconButton onClick={() => handleThemeUpdate({ paletteMode: paletteMode === "light" ? "dark" : "light" })}>
          <Badge color="error">
            <SvgIcon>
              {paletteMode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  );
};
