import { Box, Grid, Typography } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import React from "react";
import { Outlet } from "react-router-dom";

import { Loading } from "../../components";
import { useAuth } from "../../hooks/use-auth";

const Layout = () => {
  const { loading } = useAuth();
  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          flex: "1 1 auto",
        }}
      >
        <Grid container sx={{ flex: "1 1 auto" }}>
          <Loading loading={loading} style={{ zIndex: 10 }} />
          <Grid
            xs={12}
            lg={6}
            sx={{
              backgroundColor: "background.paper",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Outlet />
          </Grid>
          <Grid
            xs={12}
            lg={6}
            sx={{
              alignItems: "center",
              borderRadius: "3%",
              color: "white",
              display: "flex",
              justifyContent: "center",
              "& img": {
                maxWidth: "100%",
              },
            }}
          >
            <Box sx={{ p: 3, height: "90vh" }}>
              <img
                src={require("../../assets/img/logo.png")}
                alt="logo"
                style={{
                  width: 304.8,
                  height: 137.4,
                  marginTop: "35vh",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Layout;
