import { Provider as ReduxProvider } from "react-redux";
import { CacheProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import routes from "./pages/routes";
import { createTheme } from "./theme";
import { useNprogress } from "./hooks/use-nprogress";
import { useRoutes } from "react-router-dom";
import { store } from "./store";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AuthConsumer, AuthProvider } from "./context/auth-context";
import { Helmet } from "react-helmet-async";
import { SplashScreen, Toaster } from "./components";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SettingsConsumer, SettingsProvider } from "./context/settings-context";


function App() {
  useNprogress();
  const element = useRoutes(routes);
  const theme = createTheme();

  return (
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AuthProvider>
          <AuthConsumer>
            {(auth) => {
              const showSlashScreen = !auth.isInitialized;
              return (
                <SettingsProvider>
                  <SettingsConsumer>
                    {(settings) => {
                      if (!settings.isInitialized) {
                        // return null;
                      }
                      const theme = createTheme({
                        // contrast: settings.contrast,
                        paletteMode: settings.paletteMode,
                        // responsiveFontSizes: settings.responsiveFontSizes,
                      });

                      // Prevent guards from redirecting
                      const showSlashScreen = !auth.isInitialized;

                      return (
                        <ThemeProvider theme={theme}>
                          <Helmet>
                            <meta name="color-scheme" content="light" />
                            <meta
                              name="theme-color"
                              content={theme.palette.neutral[900]}
                            />
                          </Helmet>
                          <CssBaseline />
                          {showSlashScreen ? <SplashScreen /> : <>{element}</>}
                          <Toaster />
                        </ThemeProvider>
                      );
                    }}
                  </SettingsConsumer>
                </SettingsProvider>
              );
            }}
          </AuthConsumer>
        </AuthProvider>
      </LocalizationProvider>
    </ReduxProvider>
  );
}

export default App;
