import { baseUrl } from "./http";

const base_url = `${baseUrl()}/v1/dashboard/home`;

const apiDashboard = {
  findAll: async () => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },};

export default apiDashboard;
