const stringUtils = {
  onlyNumbers: (value) => {
    return value.replace(/[^\d]+/g, "");
  },
  cpfMask: (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  },
  hideCPFMask: (value, active) => {
    return value
      .split(".")
      .map((o, i) => {
        if (i == 0) return o;
        if (i == 1) return active ? o.split("").map((a, j) => j < 1 ? a : "*").join("") : o
        if (i == 2) return active ? o.split("").map((a, j) => j > 1 ? a : "*").join("") : o
      })
      .join(".");
  },
  phoneMask: (value) => {
    if (!value) return "";
    value = value?.replace(/\D/g, "");
    if (value?.length >= 11)
      value = value.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, "($1) $2$3-$4");
    if (value?.length >= 8)
      value = value.replace(/^(\d{2})(\d{1})(\d{4})/, "($1) $2 $3-");
    if (value?.length >= 4)
      value = value.replace(/^(\d{2})(\d{1})/, "($1) $2 ");
    if (value?.length >= 3) value = value.replace(/^(\d{2})/, "($1) ");
    return value;
  },
  emptySpaces: (value) => {
    return String(value).replace(/\s/g, "");
  },
  removeMask: (value) => {
    return value?.replace(/\W+/g, "");
  },
};

export default stringUtils;
