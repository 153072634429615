import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiDashboard from "../../api/dashboard.js";

export const findAllDashboard = createAsyncThunk(
  "dashboard/findAll",
  async (_, thunkAPI) => {
    const dashboard = await apiDashboard.findAll();
    return dashboard;
  }
);

const initialState = {
  dashboard: {
    tenantsCount: 0,
    tenantsCountEx: 0,
    tenantsCountOwner: 0,
    visitorsCount: 0,
    entranceAndExitCount: 0,
    vehiclesCount: 0,
    vehiclesCountTag: 0,
    mailCount: 0,
    mailCountLater: 0,
  },
  loading: false,
};

export const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllDashboard.fulfilled, (state, { payload }) => {
        state.dashboard = payload;
        state.loading = false;
      })
      .addCase(findAllDashboard.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllDashboard.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  reducer,
  actions: { setLoading },
} = slice;
