import { AddAPhoto, Close } from "@mui/icons-material";
import { Box, Button, Modal, Stack } from "@mui/material";
import React, { useCallback, useRef } from "react";
import Webcam from "react-webcam";

function CameraModal(props) {
  const { open, close, capturar } = props;
  const webcamRef = useRef(null);

  const capture = useCallback(() => {
      const imageSrc = webcamRef.current.getScreenshot();
      capturar(imageSrc);
  }, [webcamRef]);

  return (
    <Modal open={open} onClose={close}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Webcam
          audio={false}
          imageSmoothing={true}
          screenshotFormat="image/jpeg"
          ref={webcamRef}
          width={460.2}
          height={613.6}
          videoConstraints={{
            width: 460.2,
            height: 613.6,
            facingMode: "user",
          }}
        />
        <img
          src={require("./recomendacao.png")}
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
            opacity: 0.4,
            width: "200px",
            height: "100px",
          }}
        />
        <Stack
          spacing={2}
          direction="row"
          sx={{ position: "absolute", bottom: "10px", right: "10px" }}
        >
          <Button
            variant="contained"
            sx={{ width: "150px", ml: "auto" }}
            onClick={capture}
          >
            <AddAPhoto sx={{ mr: 0.5 }} />
            Capturar
          </Button>
          <Button
            variant="contained"
            sx={{ width: "70px", ml: 0.5 }}
            onClick={close}
          >
            <Close sx={{ mr: 0.5 }} />
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

export default CameraModal;
