import { baseUrl } from "./http";

const base_url = `${baseUrl()}/v1/visitors`;

const apiVisitors = {
  findAll: async ({ page = 0, size = 10, query = "" }) => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}?${new URLSearchParams({ page, size, query })}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  findOne: async (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateOne: async (id, body) => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            return res.json();
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createOne: async (body) => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            return res.json();
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteOneImage: (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/${id}/image`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default apiVisitors;
