import {
  ArrowCircleDown,
  ArrowCircleUp,
  ChevronLeft,
  ChevronRight,
  Close,
  DirectionsCar,
} from "@mui/icons-material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import {
  Avatar,
  Chip,
  Divider,
  Drawer,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { orderBy } from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { socket } from "../../api/socket.js";
import { getInitials } from "../../components/utils/get-initials.js";
import { withAuthGuard } from "../../guards/with-auth-guard";
import { useAuth } from "../../hooks/use-auth";
import { useSettings } from "../../hooks/use-settings";
import { useWindowSize } from "../../hooks/use-window-size";
import paths from "../../pages/paths";
import systemUtils, { permited } from "../../utils/systemUtils.js";
import { TopNav } from "./top-nav";
import toast from "react-hot-toast";

const HorizontalLayoutRoot = styled("div")(({}) => ({}));

const HorizontalLayoutContainer = styled("div")({});

const drawerWidth = 320;

const Layout = withAuthGuard((props) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const { condo, user, permission } = useAuth();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const windowSize = useWindowSize();
  const [notfy, setNotfy] = useState([]);
  const settings = useSettings();

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onEntranceEvent(value) {
      if(value.condoId === condo.id) {
        toast((t) => (
          <Stack
            direction="row"
            sx={{
              backgroundColor: "rgba(61, 61, 61, 0.1)",
              width: "310px",
              borderRadius: "10px",
              mt: "5px",
              ml: "5px",
            }}
          >
            <Avatar
              variant="rounded"
              src={value?.tenant?.image?.url || ""}
              sx={{
                mt: "5px",
                ml: "5px",
                width: 74.5524 * 0.9,
                height: 99.4032 * 0.9,
              }}
            >
              {getInitials(value.tenant?.name)}
            </Avatar>
            <Stack direction="column" sx={{ pt: "20px", pl: "5px", pr: "5px"  }}>
              <Typography variant="h6" sx={{ fontSize: 14, m: 0, pt: 0, pb: 0 }}>
                {value.tenant?.name}
              </Typography>
              <Stack direction="row" sx={{ m: 0, p: 0 }}>
                <Typography variant="subtitle2" sx={{ fontSize: 14, ml: '10px', pt: 0, pb: 0 }}>
                  {value.tenant?.unit?.name}
                </Typography>
                
                <Typography variant="body2" sx={{ fontSize: 14, ml: '20px', pt: 0, pb: 0 }}>
                  {moment(value.eventTime).format("DD/MM/YYYY HH:mm:ss")}
                </Typography>
              </Stack>
              <Stack direction="row">
                <Typography variant="subtitle2" sx={{ fontSize: 14, width: "100%", pt: 0, pb: 0 }} textAlign="center">
                  {value.deviceName}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        ), {
          duration: 5000,
          position: 'bottom-right',
        });
      }
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("entrance", onEntranceEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("entrance", onEntranceEvent);
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  let [sections] = useState([
    {
      items: [
        {
          title: "Dashboard",
          path: paths.dashboard.home,
          icon: <AssessmentIcon sx={{ color: "white" }} />,
        },
      ],
    },
    {
      items: [
        {
          title: "Cadastros",
          path: "#",
          icon: <LocalLibraryIcon sx={{ color: "white" }} />,
          items: [
            {
              title: "Moradores",
              path: paths.registers.tenants,
              disabled: systemUtils.permited(
                permission?.tenants,
                permited.read
              ),
            },
            {
              title: "Unidades",
              path: paths.registers.units,
              disabled: systemUtils.permited(permission?.units, permited.read),
            },
            {
              title: "Veículos",
              path: paths.registers.vehicles,
              disabled: systemUtils.permited(
                permission?.vehicles,
                permited.read
              ),
            },
          ],
        },
      ],
    },
    {
      name: "Controle",
      items: [
        {
          title: "Controle",
          path: "#",
          icon: <LockPersonIcon sx={{ color: "white" }} />,
          items: [
            {
              title: "Correspondências",
              path: paths.control.mail,
              disabled: systemUtils.permited(permission?.mails, permited.read),
            },
            {
              title: "Hospedes",
              path: paths.control.guests,
              disabled: systemUtils.permited(permission?.guests, permited.read),
            },
            {
              title: "Entrada e Saída",
              path: paths.control.entrance,
              disabled: systemUtils.permited(
                permission?.entrances,
                permited.read
              ),
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          title: "Relatórios",
          path: "#",
          icon: <FolderSharedIcon sx={{ color: "white" }} />,
          items: [
            {
              title: "Relatório de Correspondências",
              path: paths.report.mail,
              disabled: systemUtils.permited(
                permission?.reports,
                permited.read
              ),
            },
            {
              title: "Relatório de Visitantes",
              path: paths.report.visitors,
              disabled: systemUtils.permited(
                permission?.reports,
                permited.read
              ),
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          title: "Sistema",
          path: "#",
          icon: <SettingsApplicationsIcon sx={{ color: "white" }} />,
          items: [
            {
              title: "Usuários",
              path: paths.system.users,
              disabled: !["ADMIN", "OWNER"].includes(permission?.role),
            },
            {
              title: "Configurações",
              path: paths.system.settings,
              disabled: !["OWNER"].includes(permission?.role),
            },
          ],
        },
      ],
    },
  ]);

  // Remove section "Controle" if not enabled
  sections = !condo.conciergeControl
    ? sections.filter((o) => o.name !== "Controle")
    : sections;

  return (
    <>
      <div
        style={{
          width: open ? windowSize.width - drawerWidth : windowSize.width,
          height: "100%",
          position: "relative",
        }}
      >
        <TopNav
          sections={sections}
          settings={settings}
          handleDrawerOpen={handleDrawerOpen}
          drawerWidth={drawerWidth}
          open={open}
        />
        <HorizontalLayoutRoot drawerWidth={drawerWidth} open={open}>
          <HorizontalLayoutContainer>
            <Outlet />
          </HorizontalLayoutContainer>
        </HorizontalLayoutRoot>
      </div>
      {/* <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        {notfy?.map((item, index) => (
          <CardAccess key={index} notificacao={item} />
        ))}
      </Drawer> */}
    </>
  );
});

export default Layout;
