import { lazy, Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";

import AppLayout from "../layouts/app/layout";
import AuthLayout from "../layouts/auth/layout";
import paths from "./paths";

const SignIn = lazy(() => import("./SignIn"));
const SignUp = lazy(() => import("./SignUp"));
const RecoveryPassword = lazy(() => import("./RecoveryPassword"));
const ResetPassword = lazy(() => import("./ResetPassword"));

const Home = lazy(() => import("./Home"));
const Entrances = lazy(() => import("./Entrances"));
const Visitors = lazy(() => import("./Visitors"));
const VisitorsForm = lazy(() => import("./Visitors/Forms"));
const Units = lazy(() => import("./Units"));
const UnitsForm = lazy(() => import("./Units/Forms"));
const Tenants = lazy(() => import("./Tenants"));
const TenantsForm = lazy(() => import("./Tenants/Forms"));
const TenantsFinger = lazy(() => import("./Tenants/FingerPrints"));
const TagRFID = lazy(() => import("./Tenants/TagRFID"));
const Vehicles = lazy(() => import("./Vehicles"));
const VehiclesForm = lazy(() => import("./Vehicles/Forms"));
const TagUHF = lazy(() => import("./Vehicles/TagUHF"));
const Settings = lazy(() => import("./Settings"));
const MailList = lazy(() => import("./Mail/index.js"));
const ParkingSpaces = lazy(() => import("./Units/ParkingSpaces"));
const Users = lazy(() => import("./Users"));
const UsersForm = lazy(() => import("./Users/Forms"));
const Profile = lazy(() => import("./Profile"));
const Notifications = lazy(() => import("./Notifications"));
const NotificationsForm = lazy(() => import("./Notifications/Forms"));
const Guests = lazy(() => import("./Guests"));
const ChangeCondo = lazy(() => import("./ChangeCondo"));
const ReportMail = lazy(() => import("./ReportMail"));

const routes = [
  {
    path: paths.index.main,
    element: <AuthLayout />,
    children: [
      { path: paths.index.main, element: <Navigate to={paths.index.signin} /> },
      { path: paths.index.signin, element: <SignIn /> },
      { path: paths.index.recovery, element: <RecoveryPassword /> },
      { path: paths.index.reset, element: <ResetPassword /> },
      { path: paths.index.register, element: <SignUp /> },
    ],
  },
  {
    path: paths.dashboard.main,
    element: (
      <AppLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </AppLayout>
    ),
    children: [
      {
        path: paths.dashboard.main,
        element: <Navigate to={paths.dashboard.home} />,
      },
      { path: paths.dashboard.home, element: <Home /> },
      { path: paths.registers.tenants, element: <Tenants /> },
      { path: paths.registers.tenants + "/:id/new", element: <TenantsForm /> },
      {
        path: paths.registers.tenants + "/:id/editing",
        element: <TenantsForm />,
      },
      {
        path: paths.registers.tenants + "/:id/finger_print",
        element: <TenantsFinger />,
      },
      {
        path: paths.registers.tenants + "/:id/tag_rfid",
        element: <TagRFID />,
      },
      { path: paths.registers.units, element: <Units /> },
      { path: paths.registers.units + "/:id/new", element: <UnitsForm /> },
      { path: paths.registers.units + "/:id/editing", element: <UnitsForm /> },
      {
        path: paths.registers.units + "/:id/parking-spaces",
        element: <ParkingSpaces />,
      },
      { path: paths.registers.vehicles, element: <Vehicles /> },
      {
        path: paths.registers.vehicles + "/:id/new",
        element: <VehiclesForm />,
      },
      {
        path: paths.registers.vehicles + "/:id/editing",
        element: <VehiclesForm />,
      },
      { path: paths.registers.vehicles + "/:id/tag-uhf", element: <TagUHF /> },
      { path: paths.report.visitors, element: <Visitors /> },
      { path: paths.report.visitors + "/:id/view", element: <VisitorsForm />},
      { path: paths.control.reservations, element: <Home /> },
      { path: paths.control.mail, element: <MailList /> },
      { path: paths.control.guests, element: <Guests /> },
      { path: paths.control.gym, element: <Home /> },
      { path: paths.control.entrance, element: <Entrances /> },
      { path: paths.admin.accountability, element: <Home /> },
      { path: paths.admin.occurrences, element: <Home /> },
      { path: paths.admin.queries, element: <Home /> },
      { path: paths.admin.notifications, element: <Notifications /> },
      {
        path: paths.admin.notifications + "/:id/new",
        element: <NotificationsForm />,
      },
      {
        path: paths.admin.notifications + "/:id/editing",
        element: <NotificationsForm />,
      },
      { path: paths.syndic.messages, element: <Home /> },
      { path: paths.system.settings, element: <Settings /> },
      { path: paths.system.users, element: <Users /> },
      { path: paths.system.users + "/:id/new", element: <UsersForm /> },
      { path: paths.system.users + "/:id/editing", element: <UsersForm /> },
      { path: paths.system.account, element: <Profile /> },
      { path: paths.system.change, element: <ChangeCondo /> },
      { path: paths.report.mail, element: <ReportMail /> },
    ],
  },
];

export default routes;