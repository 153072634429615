import { baseUrl } from "./http";

const base_url = `${baseUrl()}/v1`;

const apiReport = {
  findAllMail: async ({ page = 0, size = 10, query = "", startDate, endDate, type }) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${base_url}/report/mails?${new URLSearchParams({
          page,
          size,
          query,
          startDate,
          endDate,
          type,
        })}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default apiReport