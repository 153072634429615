export default {
  index: {
    main: "/",
    signin: "/sign-in",
    register: "/sign-up",
    recovery: "/recovery",
    reset: "/reset",
  },
  dashboard: {
    main: "/app",
    home: "/app/home",
  },
  registers: {
    tenants: "/app/registers/tenants",
    units: "/app/registers/units",
    vehicles: "/app/registers/vehicles",
  },
  control: {
    reservations: "/app/reservations",
    mail: "/app/mail",
    guests: "/app/guests",
    gym: "/app/gym",
    entrance: "/app/entrance",
  },
  admin: {
    accountability: "/app/accountability",
    notifications: "/app/notifications",
    occurrences: "/app/occurrences",
    queries: "/app/queries",
  },
  syndic: {
    messages: "/app/messages",
    complaints: "/app/complaints",
    maintenance: "/app/maintenance",
  },
  system: {
    account: "/app/account",
    users: "/app/users",
    change: "/app/change",
    permissions: "/app/permissions",
    settings: "/app/settings",
  },
  report: {
    mail: "/app/report/mail",
    visitors: "/app/report/visitors"
  }
};
