import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import entranceApi from "../../api/entrance";

export const findAllEntrances = createAsyncThunk(
  "entrances/findAll",
  async ({ query='', page=1, size=5 }, thunkAPI) => {
    const entrances = await entranceApi.findAll({
      page,
      size,
      query,
    });
    return entrances;
  }
);

const initialState = {
  entrance: {},
  entrances: [],
  paginate: {
    page: 0,
    size: 5,
    count: 0,
  },
  filters: {
    query: "",
  },
  loading: false,
};

export const slice = createSlice({
  name: "entrances",
  initialState,
  reducers: {
    setEntrance: (state, action) => {
      state.entrance = action.payload;
    },
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllEntrances.fulfilled, (state, { payload }) => {
        state.entrances = payload.rows;
        state.paginate = {
          page: parseInt(payload.page),
          size: parseInt(payload.size),
          count: parseInt(payload.count),
        };
        state.loading = false;
      })
      .addCase(findAllEntrances.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllEntrances.rejected, (state) => {
        state.loading = false;
        state.entrances = [];
      });
  },
});

export const {
  reducer,
  actions: { setEntrance, setPaginate, setLoading, setFilters },
} = slice;
