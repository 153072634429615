import { baseUrl } from "./http";

const base_url = `${baseUrl()}/v1/hooks`;

const otherApi = {
  consultDocument: async (document) => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/query/cpf/${document}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            return res.json();
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  consultPlateVehicle: async (plate = "") => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/query/plate/${plate.toUpperCase().replace("-", "")}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            return res.json();
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getInfoWhatsApp: async () => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/whatsapp`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getNewInstanceWhatsApp: async () => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/whatsapp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            return res.json();
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  removeInstanceWhatsApp: async () => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/whatsapp`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default otherApi;
