import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import otherApi from "../../api/others";
import visitorsApi from "../../api/visitors";
import stringUtils from "../../utils/stringUtils";

export const findDocumentCPF = createAsyncThunk(
  "visitors/findDocumentCPF",
  async (document, thunkAPI) => {
    const person = await otherApi.consultDocument(document.replace(/\D/g, ""));
    return person;
  }
)
export const findAllVisitors = createAsyncThunk(
  "visitors/findAll",
  async ({ query, page, size }, thunkAPI) => {
    const visitors = await visitorsApi.findAll({
      page,
      size,
      query,
    });
    return visitors;
  }
);

export const findOneVisitor = createAsyncThunk(
  "visitors/findOne",
  async (id, thunkAPI) => {
    const visitors = await visitorsApi.findOne(id);
    return visitors;
  }
);

export const updateOneVisitor = createAsyncThunk(
  "visitors/updateOne",
  async ({id, body}, thunkAPI) => {
    const visitors = await visitorsApi.updateOne(id, body);
    return visitors;
  }
)

const initialState = {
  visitor: {},
  visitors: [],
  paginate: {
    page: 0,
    size: 5,
    count: 0,
  },
  filters: {
    query: "",
  },
  loading: false,
};

export const slice = createSlice({
  name: "visitors",
  initialState,
  reducers: {
    setVisitor: (state, action) => {
      state.visitor = action.payload;
    },
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllVisitors.fulfilled, (state, { payload }) => {
        state.visitors = payload.rows;
        state.paginate = {
          page: parseInt(payload.page),
          size: parseInt(payload.size),
          count: parseInt(payload.count),
        };
        state.loading = false;
      })
      .addCase(findAllVisitors.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllVisitors.rejected, (state) => {
        state.loading = false;
        state.visitors = [];
      })
      .addCase(findOneVisitor.fulfilled, (state, { payload }) => {
        state.visitor = payload;
        state.loading = false;
      })
      .addCase(findOneVisitor.pending, (state) => {
        state.loading = true;
      })
      .addCase(findOneVisitor.rejected, (state) => {
        state.loading = false;
        state.visitor = {};
      })
      .addCase(updateOneVisitor.fulfilled, (state) => {
        state.visitor = {};
        state.loading = false;
      })
      .addCase(updateOneVisitor.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOneVisitor.rejected, (state) => {
        state.loading = false;
      })
      .addCase(findDocumentCPF.fulfilled, (state, { payload }) => {
        if (payload.status === 1) {
          state.visitor = {
            ...state.visitor,
            document: stringUtils.onlyNumbers(payload.cpf),
            name: payload.nome.toUpperCase(),
            birthday: payload.nascimento
              ? moment(payload.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
              : null,
          };
        }
        state.loading = false;
      })
      .addCase(findDocumentCPF.pending, (state) => {
        state.loading = true;
      })
      .addCase(findDocumentCPF.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  reducer,
  actions: { setVisitor, setPaginate, setFilters, setLoading },
} = slice;
