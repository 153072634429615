import { baseUrl } from "./http";

const base_url = `${baseUrl()}/v1/settings`;

const apiCondos = {
  findOne: async () => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateOne: async (body) => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  findAllIntegrations: async () => {
    return new Promise((resolve, reject) => {
      fetch(
        `${baseUrl()}/v1/integrations`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteOneIntegration: async (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl()}/v1/integrations/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  addOneIntegration: async (body) => {
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl()}/v1/integrations`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateOneIntegration: async (id, body) => {
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl()}/v1/integrations/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  reSyncronizeOneIntegration: async (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${baseUrl()}/v1/integrations/${id}/re-syncronized`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            return res.json().then((error) => {
              reject(error.message);
            });
          } else {
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default apiCondos;
