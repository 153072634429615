import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';

import { createOptions as createBaseOptions } from './base/create-options';
import { createOptions as createDarkOptions } from './dark/create-options';
import { createOptions as createLightOptions } from './light/create-options';

export const createTheme = (config) => {
  let theme = createMuiTheme(
    createBaseOptions(),
    config?.paletteMode === "dark"
      ? createDarkOptions({ contrast: "high" })
      : createLightOptions({ contrast: "high" })
  );
  return theme;
};
