import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import credentialsApi from "../../api/credentials";

export const findCredential = createAsyncThunk(
  "credentials/findCredential",
  async ({ ownerId, type, option }, thunkAPI) => {
    const credential = await credentialsApi.findCredential({
      ownerId,
      type,
      option,
    });
    return credential;
  }
);

const initialState = {
  credential: {},
  loading: false,
};

export const slice = createSlice({
  name: "credentials",
  initialState,
  reducers: {
    setCredential: (state, action) => {
      state.credential = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findCredential.fulfilled, (state, { payload }) => {
        state.credential = payload;
        state.loading = false;
      })
      .addCase(findCredential.pending, (state) => {
        state.loading = true;
      })
      .addCase(findCredential.rejected, (state) => {
        state.loading = false;
        state.credential = {};
      });
  },
});

export const {
  reducer,
  actions: { setLoading, setCredential },
} = slice;
