import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import guestApi from "../../api/guests";

export const findAllGuests = createAsyncThunk(
  "guests/findAll",
  async ({ query='', page=1, size=5 }, thunkAPI) => {
    const guests = await guestApi.findAll({
      page,
      size,
      query,
    });
    return guests;
  }
);

const initialState = {
  guest: {},
  guests: [],
  paginate: {
    page: 0,
    size: 5,
    count: 0,
  },
  filters: {
    query: "",
  },
  loading: false,
};

export const slice = createSlice({
  name: "guests",
  initialState,
  reducers: {
    setGuest: (state, action) => {
      state.guest = action.payload;
    },
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllGuests.fulfilled, (state, { payload }) => {
        state.guests = payload.rows;
        state.paginate = {
          page: parseInt(payload.page),
          size: parseInt(payload.size),
          count: parseInt(payload.count),
        };
        state.loading = false;
      })
      .addCase(findAllGuests.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllGuests.rejected, (state) => {
        state.loading = false;
        state.guests = [];
      });
  },
});

export const {
  reducer,
  actions: { setGuest, setPaginate, setLoading, setFilters },
} = slice;
