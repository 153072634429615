import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import unitsApi from "../../api/units";

export const findAllUnits = createAsyncThunk(
  "units/findAll",
  async ({ query, page, size }, thunkAPI) => {
    const units = await unitsApi.findAll({
      page,
      size,
      query,
    });
    return units;
  }
);

export const findOneUnit = createAsyncThunk(
  "units/findOne",
  async (id, thunkAPI) => {
    const units = await unitsApi.findOne(id);
    return units;
  }
);

export const updateOneUnit = createAsyncThunk(
  "units/updateOne",
  async ({ id, body }, thunkAPI) => {
    const units = await unitsApi.updateOne(id, body);
    return units;
  }
);

const initialState = {
  unit: {},
  units: [],
  paginate: {
    page: 0,
    size: 5,
    count: 0,
  },
  filters: {
    query: "",
  },
  loading: false,
};

export const slice = createSlice({
  name: "units",
  initialState,
  reducers: {
    setUnit: (state, action) => {
      state.unit = action.payload;
    },
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllUnits.fulfilled, (state, { payload }) => {
        state.units = payload.rows;
        state.paginate = {
          page: parseInt(payload.page),
          size: parseInt(payload.size),
          count: parseInt(payload.count),
        };
        state.loading = false;
      })
      .addCase(findAllUnits.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllUnits.rejected, (state, { payload }) => {
        state.loading = !payload ? true : false;
        state.units = [];
      })
      .addCase(findOneUnit.fulfilled, (state, { payload }) => {
        state.unit = payload;
        state.loading = false;
      })
      .addCase(findOneUnit.pending, (state) => {
        state.loading = true;
      })
      .addCase(findOneUnit.rejected, (state) => {
        state.loading = false;
        state.unit = {};
      })
      .addCase(updateOneUnit.fulfilled, (state) => {
        state.unit = {};
        state.loading = false;
      })
      .addCase(updateOneUnit.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOneUnit.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  reducer,
  actions: { setUnit, setPaginate, setFilters, setLoading },
} = slice;
