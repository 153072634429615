const listStatusTenant = [
  { value: "IP", label: "INQUILINO PRINCIPAL" },
  { value: "IC", label: "INQUILINO CONJUGUE" },
  { value: "PR", label: "PROPRIETÁRIO" },
  { value: "MO", label: "MORADOR" },
  { value: "PM", label: "PROPRIETÁRIO MORADOR" },
  { value: "PC", label: "PROPRIETÁRIO CONJUGUE" },
  { value: "EX", label: "EX-MORADOR" },
  { value: "FN", label: "FUNCIONÁRIO" },
  { value: "EF", label: "Ex-FUNCIONÁRIO" },
];

const listStatusMail = [
  { value: "ORDER", label: "Encomenda" },
  { value: "REGISTERED_LETTER", label: "Carta Registrada" },
  { value: "CORRESPONDENCE", label: "Correspondência" },
];

const listBrandIntegration = [
  {
    value: "INTELBRAS",
    label: "INTELBRAS",
    devices: [
      { value: "INCONTROL", label: "INCONTROL", face_recognition: true, tag_rfid: true, qrcode: true, tag_uhf: true, password: true, fingerprint: true },
      { value: "SS1530MFW", label: "SS 1530 MF W", face_recognition: true, tag_rfid: true, qrcode: false, tag_uhf: false, password: true, fingerprint: false },
    ],
  },
];

const systemUtils = {
  stTenant: (status) => {
    return status
      ? listStatusTenant.filter(({ value }) => value === status)[0]?.label
      : "";
  },
  lstStatusTenant: () => {
    return listStatusTenant;
  },
  lstBrandsIntegration: () => {
    return listBrandIntegration;
  },
  lstStatusMail: () => {
    return listStatusMail;
  },
  stMail: (status) => {
    return status
      ? listStatusMail.filter(({ value }) => value === status)[0]?.label
      : "";
  },
  permited: (permission, type) => {
    switch (type) {
      case permited.read:
        return !(permission >= 1);
      case permited.write:
        return !(permission >= 2);
      case permited.delete:
        return !(permission >= 3);
      case permited.all:
        return !(permission >= 4);
      default:
        return true;
    }
  },
};

export const integrationType = {
  TAG_RFID: "TAG_RFID",
  TAG_UHF: "TAG_UHF",
  FINGER_PRINT: "FINGER_PRINT",
  QRCODE: "QRCODE",
  FACE_RECOGNITION: "FACE_RECOGNITION",
}

export const permited = {
  none: 0,
  read: 1,
  write: 2,
  delete: 3,
  all: 4,
};

export default systemUtils;
