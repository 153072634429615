import jwt_decode from "jwt-decode";
import PropTypes from "prop-types";
import { createContext, useCallback, useEffect, useReducer } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

import authApi from "../api/auth";
import otherApi from "../api/others";
import useInterval from "../hooks/use-interval";

const STORAGE_KEY = "accessToken";

var ActionType;
(function (ActionType) {
  ActionType["INITIALIZE"] = "INITIALIZE";
  ActionType["SIGN_IN"] = "SIGN_IN";
  ActionType["SIGN_INC"] = "SIGN_INC";
  ActionType["SIGN_UP"] = "SIGN_UP";
  ActionType["SIGN_OUT"] = "SIGN_OUT";
  ActionType["STATUS"] = "STATUS";
  ActionType["LOADING"] = "LOADING";
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  condo: null,
  condos: null,
  integrations: null,
  whatsapp: null,
  permission: null,
  loading: false,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, condo, condos, permission } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      condo,
      condos,
      permission,
    };
  },
  STATUS: (state, action) => {
    const { whatsapp } = action.payload;
    return {
      ...state,
      whatsapp,
    };
  },
  SIGN_IN: (state, action) => {
    const { user, condo, condos, permission } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      condo,
      condos,
      permission,
    };
  },
  SIGN_INC: (state, action) => {
    const { user, condos, permission } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      condos,
      permission,
    };
  },
  SIGN_UP: (state, action) => {
    const { user, condo, condos, permission } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      condo,
      condos,
      permission,
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    condo: null,
    condos: null,
    permission: null,
  }),
  LOADING: (state, action) => ({
    ...state,
    loading: action.payload,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialState,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  changeCondo: () => Promise.resolve(),
  changeStatus: (integrations) => Promise.resolve(),
  reInitialize: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const reInitialize = useCallback(async () => {
    try {
      const accessToken = window.sessionStorage.getItem(STORAGE_KEY);
      if (accessToken) {
        const { user, condo, condos, permission, token } =
          await authApi.getAutenticated(accessToken);
        if (!user) {
          window.sessionStorage.removeItem(STORAGE_KEY);
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              condo: null,
              condos: null,
              permission: null,
            },
          });
          navigate("/", { replace: true });
        } else {
          console.log(condo);
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
              condo,
              condos,
              permission,
            },
          });
          window.sessionStorage.setItem(STORAGE_KEY, token);
          // changeStatus(integrations);
        }
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            condo: null,
            condos: null,
            permission: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
          condo: null,
          condos: null,
          permission: null,
        },
      });
    }
  }, [dispatch]);

  useEffect(() => {
    reInitialize();
  }, []);

  const changeCondo = useCallback(
    async (condoId = "") => {
      dispatch({
        type: ActionType.LOADING,
        payload: true,
      });
      const { user, condo, condos, permission, token } =
        await authApi.getChangeCondo(condoId);
      window.sessionStorage.setItem(STORAGE_KEY, token);
      dispatch({
        type: ActionType.LOADING,
        payload: false,
      });
      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user,
          condo,
          condos,
          permission,
        },
      });
    },
    [dispatch]
  );

  const signIn = useCallback(
    async (email, password, condoId = "") => {
      dispatch({
        type: ActionType.LOADING,
        payload: true,
      });
      const { user, condo, condos, permission, token } = await authApi.signIn(
        email,
        password,
        condoId
      );

      if (!user) {
        toast.error("E-mail ou senha inválidos");
        dispatch({
          type: ActionType.LOADING,
          payload: false,
        });
        return;
      }

      if (!token) {
        dispatch({
          type: ActionType.SIGN_INC,
          payload: {
            user,
            condos,
          },
        });
      } else {
        window.sessionStorage.setItem(STORAGE_KEY, token);
        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            user,
            condo,
            condos,
            permission,
          },
        });
        // changeStatus(integrations);
      }
      dispatch({
        type: ActionType.LOADING,
        payload: false,
      });
    },
    [dispatch]
  );

  const signUp = useCallback(
    async (payload) => {
      const { user, condo, condos, permission, token } = await authApi.signUp(
        payload
      );
      window.sessionStorage.setItem(STORAGE_KEY, token);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
          condo,
          condos,
          permission,
        },
      });
    },
    [dispatch]
  );

  const signOut = useCallback(async () => {
    dispatch({
      type: ActionType.LOADING,
      payload: true,
    });
    window.sessionStorage.removeItem(STORAGE_KEY);
    dispatch({ type: ActionType.SIGN_OUT });
    dispatch({
      type: ActionType.LOADING,
      payload: false,
    });
    navigate("/", { replace: true });
  }, [dispatch]);

  const changeStatus = useCallback(
    async (integrations) => {
      // const integration = integrations?.filter((o) => o.typeIntegration == "WHATSAPP_BUSINESS").length > 0;
      // if (integration) {
      //   const { instance_data } = await otherApi.getInfoWhatsApp();
      //   dispatch({
      //     type: ActionType.STATUS,
      //     payload: {
      //       whatsapp: instance_data?.phone_connected ?? false,
      //     },
      //   });
      // } else {
      //   dispatch({
      //     type: ActionType.STATUS,
      //     payload: {
      //       whatsapp: null,
      //     },
      //   });
      // }
    },
    [dispatch]
  );

  useInterval(
    () => {
      const token = window.sessionStorage.getItem(STORAGE_KEY);
      if (token) {
        let decoded = jwt_decode(token);
        if (decoded.exp < Date.now() / 1000) {
          signOut();
        } else if (decoded.exp < Date.now() / 1000 + 60) {
          reInitialize();
        }
      }
    },
    30000,
    false
  );

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut,
        changeCondo,
        changeStatus,
        reInitialize,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
