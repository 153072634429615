import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import otherApi from "../../api/others";
import tenantsApi from "../../api/tenants";
import stringUtils from "../../utils/stringUtils";

export const findDocumentCPF = createAsyncThunk(
  "tenants/findDocumentCPF",
  async (document, thunkAPI) => {
    const person = await otherApi.consultDocument(document);
    return person;
  }
)

export const findAllTenants = createAsyncThunk(
  "tenants/findAll",
  async ({ query, page, size }, thunkAPI) => {
    const tenants = await tenantsApi.findAll({
      page,
      size,
      query,
    });
    if (!tenants) {
      thunkAPI.abort("Tenants not found");
    }
    return tenants;
  }
);

export const findOneTenant = createAsyncThunk(
  "tenants/findOne",
  async (id, thunkAPI) => {
    const tenants = await tenantsApi.findOne(id);
    return tenants;
  }
);

export const updateOneTenant = createAsyncThunk(
  "tenants/updateOne",
  async ({id, body}, thunkAPI) => {
    const tenants = await tenantsApi.updateOne(id, body);
    return tenants;
  }
)

const initialState = {
  tenant: {},
  tenants: [],
  paginate: {
    page: 0,
    size: 5,
    count: 0
  },
  loading: false,
  filters: {
    query: "",
  }
};

export const slice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    setTenant: (state, action) => {
      state.tenant = action.payload;
    },
    setPaginate: (state, action) => {
      state.paginate = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAllTenants.fulfilled, (state, { payload }) => {
        state.tenants = payload.rows;
        state.paginate = {
          page: parseInt(payload.page),
          size: parseInt(payload.size),
          count: parseInt(payload.count),
        };
        state.loading = false;
      })
      .addCase(findAllTenants.pending, (state) => {
        state.loading = true;
      })
      .addCase(findAllTenants.rejected, (state, { payload }) => {
        state.loading = !payload ? true : false;
        state.tenants = [];
      })
      .addCase(findOneTenant.fulfilled, (state, { payload }) => {
        state.tenant = {
          ...payload,
          unitId: payload.unit?.id,
          unitTitle: payload.unit?.name,
        };
        state.loading = false;
      })
      .addCase(findOneTenant.pending, (state) => {
        state.loading = true;
      })
      .addCase(findOneTenant.rejected, (state) => {
        state.loading = false;
        state.tenant = {};
      })
      .addCase(updateOneTenant.fulfilled, (state) => {
        state.tenant = {};
        state.loading = false;
      })
      .addCase(updateOneTenant.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateOneTenant.rejected, (state) => {
        state.loading = false;
      })
      .addCase(findDocumentCPF.fulfilled, (state, { payload }) => {
        if (payload.status === 1) {
          state.tenant = {
            ...state.tenant,
            document: stringUtils.onlyNumbers(payload.cpf),
            name: payload.nome.toUpperCase(),
            birthday: payload.nascimento
              ? moment(payload.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
              : null,
          };
        }
        state.loading = false;
      })
      .addCase(findDocumentCPF.pending, (state) => {
        state.loading = true;
      })
      .addCase(findDocumentCPF.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  reducer,
  actions: { setTenant, setPaginate, setFilters, setLoading },
} = slice;
