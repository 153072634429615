import React from "react";
import MaskedInput from "react-text-mask";
import { NumericFormat } from "react-number-format";
import { TextField } from "@mui/material";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      mask={props.mask}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, value, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator={"."}
      isNumericString
      prefix="R$ "
      decimalScale={2}
      decimalSeparator={","}
      fixedDecimalScale={true}
      onValueChange={(values) => {
        onChange({
          target: {
            values,
          },
        });
      }}
      value={value}
    />
  );
}

const InputMaskField = (props) => {
  const { endAdornment } = props;
  return props.isNumber === false ? (
    <TextField
      {...props}
      className={props.className}
      label={props.label}
      value={props.value || ""}
      onChange={props.onChange}
      onBlur={props.onBlur}
      id={props.id}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: TextMaskCustom,
        inputProps: { ...props },
        endAdornment,
      }}
    />
  ) : (
    <TextField
      {...props}
      className={props.className}
      label={props.label}
      value={props.value || ""}
      onChange={props.onChange}
      id={props.id}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: NumberFormatCustom,
        inputProps: { ...props },
      }}
    />
  );
};

export default InputMaskField;
