import { baseUrl } from "./http";
const base_url = `${baseUrl()}/v1/admin/vehicles/tag/uhf`;

const apiVehiclesTagUHF = {
  findOne: async (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateOne: async (id, body) => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(body),
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteOne: async (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${base_url}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default apiVehiclesTagUHF;