import styled from "@emotion/styled";
import { Breadcrumbs, Chip, emphasize } from "@mui/material";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

function MyBreadcrumbs({ breadcrumps = [] }) {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon />}
      sx={{ marginTop: "5px" }}
    >
      {breadcrumps.map((breadcrump, index) => (
        <StyledBreadcrumb
          key={index}
          component="b"
          label={breadcrump.label}
          icon={breadcrump.icon}
        />
      ))}
    </Breadcrumbs>
  );
}

export default MyBreadcrumbs;
